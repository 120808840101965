@import "../../styles/base.scss";
@import "../../styles/page_layout.scss";

.nav{
    height: $navHeight;
    width: 100%;
    max-width: 100vw;
    z-index: 1020;

    transition: transform 0.3s ease;
    background-color: $navBackgroundColor;
    color:black;
    transform: translateY(0);
    position: fixed;
    transition: 0.3s;
    backdrop-filter: blur(5px);
}
.nav-transparent{
    transition: 0.3s;
    background-color: transparent !important;
    backdrop-filter: blur(0);
}

.hidden-nav{
    transform: translateY(-$navHeight);
}

.nav-center-col{
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    height: $navHeight;
}

.nav-content-container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    height: $navHeight;
}

.sub-menu{
    @extend .hv-center;
    flex-direction: column;
    position: absolute;
    background-color: rgba(232, 232, 232, 0.8);
    top:100%;
    width: 1.5*$navHeight;
    border-radius: 7px;
    height: auto;
    animation: sub-menu-animation-in 0.15s linear;
    backdrop-filter: blur(5px);
    
    &.fade-out{
        animation: sub-menu-animation-out 0.15s linear forwards;
    }

    .sub-menu-item{
        @extend .hv-center;
        flex-direction: column;
        height: 30px;
        width: 100%;
        color:rgb(0, 20, 71);
        transition: 0.3s;
        border-radius: 7px;
        user-select: none;

        &:hover{
            background-color: rgba(255, 255, 255, 0.3);
        }

        a{
            text-decoration: none;
            color: $postColor;
        }
    }

}

@keyframes sub-menu-animation-in{
    from{
        opacity: 0;
        // transform: translateY(-150px);
        // transform: translateY(10px);
        transform: translateY(-10px);
    }
    to{
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes sub-menu-animation-out{
    from{
        opacity: 1;
        transform: translateY(0);
    }to{
        opacity: 0;
        transform: translateY(-10px);
        display: none;
    }
}

.click-container{
    display: flex;
    color:white;
    user-select: none;
    font-size:20px;
    transition: 0.3s;
    height: $navHeight;
    justify-content: center;
    align-items: center;
    width: 1.3*$navHeight;
    border-radius: 3px;
    &:hover{
        background-color: rgba(0, 128, 225, 0.7);
        // transform: scale(1.1);
    }
    // text-shadow: 1px 1px 5px black;

    .nav-click-text-container{
        font-size: 15px;
        margin-left: 5px;

        .nav-click-text-icon{
            margin-left:4px;
        }
    }
}

.nav-tool-click-container{
    display: flex;
    color:white;
    font-size:20px;
    transition: 0.3s;
    height: $navHeight;
    justify-content: center;
    align-items: center;
    width: $navHeight;
    cursor: pointer;

    &:hover{
        transform: scale(1.2);
    }
}


//移动端隐藏Nav
@media screen and (max-width:$mobileMaxWidth) {
    .nav{
        transform: translateY(-$navHeight);
        transition: transform 0s ease;
        display: none;
    }
}


// 夜间模式
.nav-dark{
    @extend .nav;
    background-color: transparentize($themeBackgroundColor-dark,.4);
}

.sub-menu-dark{
    @extend .sub-menu;
    background-color: transparentize($themeBackgroundColor-dark,.4);
    
    .sub-menu-item-dark{
        @extend .sub-menu-item;
        
        a{
            text-decoration: none;
            color: #fff;
        }
    }
}

.click-container-dark{
    @extend .click-container;

    &:hover{
        background-color: rgba(96, 75, 172, 0.7);
    }
}