* {
    box-sizing: border-box
}

//flex-hv居中
.hv-center{
    display: flex;
    align-items: center;
    justify-content: center;
}

.page-main{
    @extend .hv-center;
    width: 100%;
    max-width: 100vw;
    height: auto;
    flex-direction: column;
    margin-bottom: 15vh;
    padding: 0 13vw;

    .page-main-title{
        @extend .hv-center;
        margin-top:17vh;
        width: 100%;
    }

    .page-main-content{
        @extend .hv-center;
        margin: 10vh 5vw 0 5vw;
        width: 100%;
        display: flex;
        flex-direction: column;
    }
}


@media screen and (max-width:992px) {
    .page-main{
        padding: 0;
    }
}
