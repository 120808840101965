@import "../../styles/page_layout.scss";
@import "../../styles/base.scss";

// .loading-main{
//     @extend .hv-center;
//     position: fixed;
//     width: 100vw;
//     height: 100vh;
//     max-width: 100vw;
//     max-height: 100vh;
//     z-index: 2000;
//     background: linear-gradient(rgb(56, 132, 193),#393958);

//     .loading-title{
//         font-size: 70px;
//         color: white;
//         font-family: "CustomFont1";
//         animation: title-text 3s;
//         animation-timing-function: linear;
//         animation-iteration-count: infinite;
//     }

//     .loading-icon{
//         font-size: 70px;
//         color: white;
//         animation: title-icon 3s;
//         animation-timing-function: linear;
//         animation-iteration-count: infinite;
//         margin-right: 50px;
//     }

//     @media screen and (max-width:$mobileMaxWidth) {
//         .loading-title, .loading-icon{
//             font-size: 50px;
//         }
//     }
// }


// @keyframes title-text{
//     0%{
//         transform: scale(1);
//     }
//     50%{
//         transform: scale(1.1);
//     }
//     100%{
//         transform: scale(1);
//     }
// }

// @keyframes title-icon{
//     from{
//         rotate: 0deg;
//     }
//     to{
//         rotate: 360deg;
//     }
// }

// .loading-main-dark{
//     @extend .loading-main;
//     background: #393958;
// }

.loading-main{
    position: fixed;
    width: 100vw;
    height: 100vh;
    max-width: 100vw;
    max-height: 100vh;
    z-index: 2500;
    background: mix($themeBackgroundColor,#fff,45%);
    // animation: fadeIn 0.5s forwards;

    &.dark{
        background: $themeBackgroundColor-dark;
    }

    // &.fade-out {
    //     animation: fadeOut 0.5s forwards;
    // }


    .loader{
        $color1:mix($themeBackgroundColor,#000,80%);
        $color2:mix($dark-highlight-color,#000,80%);

        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        width: 200px;
        height: 200px;
        box-sizing: border-box;
        // background: #000;

        .face{
            position: absolute;
            border: 2px solid #063e9815;

            &.face1{
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                // background-color: #fff;
                // box-shadow: 0 0 3px #00000011;
                border-radius: 50%;
                border-left: 2px solid $color1;
                border-top: 2px solid $color1;
                animation: animate 3s linear infinite;

                .circle{
                    transform: rotate(-45deg);

                    &::before{
                        background: $color1;
                        box-shadow: 0 0 20px $color1, 
                                    0 0 40px $color1,
                                    0 0 60px $color1,
                                    0 0 80px $color1,
                                    0 0 100px $color1;
                    }
                }
            }

            &.face2{
                top: 30px;
                left: 30px;
                right: 30px;
                bottom: 30px;
                // background-color: #fff;
                // box-shadow: 0 0 3px #00000033;
                border-radius: 50%;
                border-right: 2px solid $color2;
                border-bottom: 2px solid $color2;
                animation: animate 3s linear reverse infinite;

                .circle{
                    transform: rotate(-45deg);

                    &::before{
                        background: $color2;
                        box-shadow: 0 0 20px $color2, 
                                    0 0 40px $color2,
                                    0 0 60px $color2,
                                    0 0 80px $color2,
                                    0 0 100px $color2;
                    
                    }
                }
            }

            .circle{
                position: absolute;
                top: calc(50% - 1px);
                left: 50%;
                width: 50%;
                height: 2px;
                // background: #fff;
                transform-origin: left;

                &::before{
                    content: '';
                    position: absolute;
                    width: 10px;
                    height: 10px;
                    border-radius: 50%;
                    background: #fff;
                    top: -4px;
                    right: -6px;
                }
            }
        }

    }

    .loader-dark{
        @extend .loader;
        $color1:mix($themeBackgroundColor,#fff,30%);
        $color2:mix($dark-highlight-color,#fff,30%);

        .face{
            position: absolute;
            border: 2px solid #063e9815;

            &.face1{
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                // background-color: #fff;
                // box-shadow: 0 0 3px #00000011;
                border-radius: 50%;
                border-left: 2px solid $color1;
                border-top: 2px solid $color1;
                animation: animate 3s linear infinite;

                .circle{
                    transform: rotate(-45deg);

                    &::before{
                        background: $color1;
                        box-shadow: 0 0 20px $color1, 
                                    0 0 40px $color1,
                                    0 0 60px $color1,
                                    0 0 80px $color1,
                                    0 0 100px $color1;
                    }
                }
            }

            &.face2{
                top: 30px;
                left: 30px;
                right: 30px;
                bottom: 30px;
                // background-color: #fff;
                // box-shadow: 0 0 3px #00000033;
                border-radius: 50%;
                border-right: 2px solid $color2;
                border-bottom: 2px solid $color2;
                animation: animate 3s linear reverse infinite;

                .circle{
                    transform: rotate(-45deg);

                    &::before{
                        background: $color2;
                        box-shadow: 0 0 20px $color2, 
                                    0 0 40px $color2,
                                    0 0 60px $color2,
                                    0 0 80px $color2,
                                    0 0 100px $color2;
                    
                    }
                }
            }

            .circle{
                position: absolute;
                top: calc(50% - 1px);
                left: 50%;
                width: 50%;
                height: 2px;
                // background: #fff;
                transform-origin: left;

                &::before{
                    content: '';
                    position: absolute;
                    width: 10px;
                    height: 10px;
                    border-radius: 50%;
                    background: #fff;
                    top: -4px;
                    right: -6px;
                }
            }
        }
    }
}

@keyframes animate{
    0%{
        transform: rotate(0deg);
    }
    100%{
        transform: rotate(360deg);
    }
}

// @keyframes fadeIn{
//     0%{
//         opacity: 0;
//     }
//     100%{
//         opacity: 1;
//     }
// }

// @keyframes fadeOut {
//     0% {
//       opacity: 1;
//     }
//     100% {
//       opacity: 0;
//     }
//   }
  