@import "../../styles/base.scss";

.loading-transition-enter {
    opacity: 0;
}

.loading-transition-enter-active {
    opacity: 1;
    transition: opacity 150ms ease-in-out;
}

.loading-transition-exit {
    opacity: 1;
}

.loading-transition-exit-active {
    opacity: 0;
    transition: opacity 150ms ease-in-out;
}

.page-transition-enter {
    opacity: 0;
}

.page-transition-enter-active {
    opacity: 1;
    transition: opacity 150ms ease-in-out;
}

.page-transition-exit {
    opacity: 1;
}

.page-transition-exit-active {
    opacity: 0;
    transition: opacity 150ms ease-in-out;
}